

import * as Uuid from "../../../bindings/uuid/Uuid.mjs";
import * as Types from "../../../types/Types.mjs";
import * as Js_array from "rescript/lib/es6/js_array.js";
import * as JsonDecode from "../../../libraries/JsonDecode.mjs";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Shared_Lib_Option from "../../../shared/lib/Shared_Lib_Option.mjs";

function fromString(dataType) {
  switch (dataType) {
    case "ad_stat" :
        return "AdStat";
    case "birthday" :
        return "Birthday";
    case "checkbox" :
        return "Checkbox";
    case "date" :
        return "Date";
    case "datetime" :
        return "Datetime";
    case "items" :
        return "Items";
    case "legal_entity" :
        return "LegalEntity";
    case "multiselect" :
        return "MultiSelect";
    case "multitext" :
        return "MultiText";
    case "numeric" :
        return "Numeric";
    case "org_legal_name" :
        return "OrgLegalName";
    case "radiobutton" :
        return "RadioButton";
    case "select" :
        return "Select";
    case "smartaddress" :
        return "SmartAddress";
    case "status" :
        return "Status";
    case "streetaddress" :
        return "StreetAddress";
    case "text" :
        return "Text";
    case "textarea" :
        return "TextArea";
    case "url" :
        return "URL";
    case "user" :
        return "User";
    default:
      return {
              TAG: "Unknown",
              _0: dataType
            };
  }
}

function fromStringLifter(v) {
  return Shared_Lib_Option.some(fromString(v));
}

function toString(dataType) {
  if (typeof dataType === "object") {
    return dataType._0;
  }
  switch (dataType) {
    case "Text" :
        return "text";
    case "Numeric" :
        return "numeric";
    case "Checkbox" :
        return "checkbox";
    case "Select" :
        return "select";
    case "MultiSelect" :
        return "multiselect";
    case "Date" :
        return "date";
    case "URL" :
        return "url";
    case "MultiText" :
        return "multitext";
    case "TextArea" :
        return "textarea";
    case "RadioButton" :
        return "radiobutton";
    case "StreetAddress" :
        return "streetaddress";
    case "SmartAddress" :
        return "smartaddress";
    case "Birthday" :
        return "birthday";
    case "LegalEntity" :
        return "legal_entity";
    case "Items" :
        return "items";
    case "OrgLegalName" :
        return "org_legal_name";
    case "Datetime" :
        return "datetime";
    case "User" :
        return "user";
    case "AdStat" :
        return "ad_stat";
    case "Status" :
        return "status";
    
  }
}

var decode = JsonDecode.object(function (field) {
      return {
              id: field.required("id", Types.Id.decodeOpaque),
              name: field.required("name", JsonDecode.string),
              fieldType: field.required("fieldType", JsonDecode.map(JsonDecode.string, fromString)),
              multiple: field.required("multiple", JsonDecode.bool)
            };
    });

function fromString$1(lead) {
  switch (lead) {
    case "lead_id" :
        return "LeadId";
    case "lead_name" :
        return "LeadName";
    case "lead_status" :
        return "LeadStatus";
    case "responsible_user" :
        return "ResponsibleUser";
    case "sale" :
        return "Sale";
    default:
      return {
              TAG: "Unknown",
              _0: lead
            };
  }
}

function fromStringLifter$1(v) {
  return Shared_Lib_Option.some(fromString$1(v));
}

function toString$1(lead) {
  if (typeof lead === "object") {
    return lead._0;
  }
  switch (lead) {
    case "LeadId" :
        return "lead_id";
    case "LeadName" :
        return "lead_name";
    case "LeadStatus" :
        return "lead_status";
    case "ResponsibleUser" :
        return "responsible_user";
    case "Sale" :
        return "sale";
    
  }
}

function dataType(lead) {
  if (typeof lead === "object") {
    return "Text";
  }
  switch (lead) {
    case "LeadName" :
        return "Text";
    case "LeadStatus" :
        return "Status";
    case "ResponsibleUser" :
        return "User";
    case "LeadId" :
    case "Sale" :
        return "Numeric";
    
  }
}

function toId(t) {
  return Types.Id.fromString(toString$1(t));
}

function fromId(id) {
  return fromString$1(Types.Id.toString(id));
}

var all = [
  "LeadId",
  "LeadName",
  "Sale",
  "LeadStatus",
  "ResponsibleUser"
];

function fromString$2(contact) {
  if (contact === "contact_name") {
    return "ContactName";
  } else {
    return {
            TAG: "Unknown",
            _0: contact
          };
  }
}

function fromStringLifter$2(v) {
  return Shared_Lib_Option.some(fromString$2(v));
}

function toString$2(contact) {
  if (typeof contact !== "object") {
    return "contact_name";
  } else {
    return contact._0;
  }
}

function toId$1(t) {
  return Types.Id.fromString(toString$2(t));
}

var all$1 = ["ContactName"];

function getId(leadField) {
  if (leadField.TAG === "PredefinedField") {
    return toId(leadField._0);
  } else {
    return leadField._0;
  }
}

function makeFromCustomField(f) {
  return {
          TAG: "CustomField",
          _0: f.id,
          _1: f.fieldType,
          _2: f.multiple
        };
}

function makeFromPredefinedLead(f) {
  return {
          TAG: "PredefinedField",
          _0: f
        };
}

var decode$1 = JsonDecode.object(function (field) {
      var code = field.optional("predefinedLeadField", JsonDecode.string);
      if (code !== undefined) {
        return {
                TAG: "PredefinedField",
                _0: fromString$1(code)
              };
      } else {
        return {
                TAG: "CustomField",
                _0: field.required("leadCustomFieldId", Types.Id.decodeOpaque),
                _1: field.required("fieldType", JsonDecode.map(JsonDecode.string, fromString)),
                _2: field.required("multiple", JsonDecode.bool)
              };
      }
    });

function getId$1(contactField) {
  return contactField._0;
}

function makeFromCustomField$1(f) {
  return {
          TAG: "CustomField",
          _0: f.id,
          _1: f.fieldType,
          _2: f.multiple
        };
}

function make(id, fieldType, multiple) {
  return {
          TAG: "CustomField",
          _0: id,
          _1: fieldType,
          _2: multiple
        };
}

var ContactField = {
  getId: getId$1,
  makeFromCustomField: makeFromCustomField$1,
  make: make
};

function getId$2(amocrmField) {
  switch (amocrmField.TAG) {
    case "PredefinedLead" :
        return toId(amocrmField._0);
    case "PredefinedContact" :
        return toId$1(amocrmField._0);
    case "LeadField" :
        var field = amocrmField._0;
        if (field.TAG === "PredefinedField") {
          return toId(field._0);
        } else {
          return field._0;
        }
    case "ContactField" :
        return amocrmField._0._0;
    
  }
}

function dataType$1(field) {
  switch (field.TAG) {
    case "PredefinedLead" :
        return dataType(field._0);
    case "PredefinedContact" :
        return "Text";
    case "LeadField" :
        var field$1 = field._0;
        if (field$1.TAG === "PredefinedField") {
          return dataType(field$1._0);
        } else {
          return field$1._1;
        }
    case "ContactField" :
        return field._0._1;
    
  }
}

var decode$2 = JsonDecode.object(function (field) {
      var f = field.optional("predefinedLeadField", JsonDecode.map(JsonDecode.string, fromString$1));
      if (f !== undefined) {
        return {
                TAG: "PredefinedLead",
                _0: f
              };
      }
      var f$1 = field.optional("predefinedContactField", JsonDecode.map(JsonDecode.string, fromString$2));
      if (f$1 !== undefined) {
        return {
                TAG: "PredefinedContact",
                _0: f$1
              };
      }
      var fieldType = field.required("fieldType", JsonDecode.map(JsonDecode.string, fromString));
      var multiple = field.required("multiple", JsonDecode.bool);
      var id = field.optional("leadCustomFieldId", Types.Id.decodeOpaque);
      if (id !== undefined) {
        return {
                TAG: "LeadField",
                _0: {
                  TAG: "CustomField",
                  _0: Caml_option.valFromOption(id),
                  _1: fieldType,
                  _2: multiple
                }
              };
      }
      var id$1 = field.required("contactCustomFieldId", Types.Id.decodeOpaque);
      return {
              TAG: "ContactField",
              _0: {
                TAG: "CustomField",
                _0: id$1,
                _1: fieldType,
                _2: multiple
              }
            };
    });

var AmocrmField = {
  getId: getId$2,
  dataType: dataType$1,
  decode: decode$2
};

function fromString$3(dataType) {
  switch (dataType) {
    case "attachment" :
        return "Attachment";
    case "boolean" :
        return "Boolean";
    case "currency" :
        return "Currency";
    case "date" :
        return "Date";
    case "datetime" :
        return "Datetime";
    case "decimal" :
        return "Decimal";
    case "dictionary" :
        return "Dictionary";
    case "integer" :
        return "Integer";
    case "string" :
        return "String";
    case "url" :
        return "URL";
    default:
      return "Unknown";
  }
}

function fromStringLifter$3(v) {
  return Shared_Lib_Option.some(fromString$3(v));
}

var all$2 = [
  "Territory",
  "JobType",
  "Description",
  "Assignee",
  "FinishedAt",
  "ScheduledAt",
  "ScheduledAtDate",
  "ScheduledAtTime",
  "ScheduledDuration",
  "Address",
  "AddressDescription",
  "Apartment",
  "Floor",
  "EntranceNo",
  "Contacts",
  "ContactName",
  "ContactValue",
  "Resolution",
  "ResolutionComment",
  "SerialNo",
  "PhotoArchiveUrlShared",
  "JobLink"
];

function toString$3(predefined) {
  switch (predefined) {
    case "Territory" :
        return "territory_uuid";
    case "JobType" :
        return "type_uuid";
    case "Description" :
        return "description";
    case "Assignee" :
        return "assignee";
    case "FinishedAt" :
        return "finished_at";
    case "ScheduledAt" :
        return "scheduled_at";
    case "ScheduledAtDate" :
        return "scheduled_at.date";
    case "ScheduledAtTime" :
        return "scheduled_at.time";
    case "ScheduledDuration" :
        return "scheduled_duration";
    case "Address" :
        return "address";
    case "AddressDescription" :
        return "address_details.description";
    case "Apartment" :
        return "address_details.apartment";
    case "Floor" :
        return "address_details.floor";
    case "EntranceNo" :
        return "address_details.entrance_no";
    case "Contacts" :
        return "contacts";
    case "ContactName" :
        return "contacts.0.name";
    case "ContactValue" :
        return "contacts.0.value";
    case "Resolution" :
        return "resolution";
    case "ResolutionComment" :
        return "resolution_comment";
    case "SerialNo" :
        return "serial_no";
    case "PhotoArchiveUrlShared" :
        return "photo_archive_url_shared";
    case "JobLink" :
        return "job_link";
    
  }
}

function fromString$4(str) {
  return Js_array.find((function (f) {
                return toString$3(f) === str;
              }), all$2);
}

function toId$2(v) {
  return Types.Id.fromString(toString$3(v));
}

function toDataType(t) {
  switch (t) {
    case "Territory" :
        return "Territory";
    case "JobType" :
        return "JobType";
    case "Assignee" :
        return "Assignee";
    case "FinishedAt" :
        return "Datetime";
    case "ScheduledAt" :
        return "Time";
    case "ScheduledAtDate" :
        return "Date";
    case "ScheduledAtTime" :
        return "TimeString";
    case "ScheduledDuration" :
        return "Duration";
    case "Address" :
        return "Address";
    case "Contacts" :
        return "Contacts";
    case "Resolution" :
        return "Resolution";
    case "SerialNo" :
        return "Integer";
    case "PhotoArchiveUrlShared" :
    case "JobLink" :
        return "URL";
    default:
      return "String";
  }
}

function getId$3(jobField) {
  switch (jobField.TAG) {
    case "Predefined" :
        return toId$2(jobField._0);
    case "Custom" :
    case "Report" :
        return jobField._0;
    
  }
}

var decode$3 = JsonDecode.object(function (field) {
      var f = field.optional("code", JsonDecode.fromVariant(JsonDecode.string, fromString$4));
      if (f !== undefined) {
        return {
                TAG: "Predefined",
                _0: f
              };
      }
      var uuid = field.optional("customFieldUuid", Types.Id.decodeOpaque);
      if (uuid !== undefined) {
        return {
                TAG: "Custom",
                _0: Caml_option.valFromOption(uuid)
              };
      } else {
        return {
                TAG: "Report",
                _0: field.required("reportFieldUuid", Types.Id.decodeOpaque)
              };
      }
    });

var decode$4 = JsonDecode.object(function (field) {
      return {
              jobField: field.required("jobField", decode$3),
              amocrmField: field.required("amocrmField", decode$2)
            };
    });

var JobFieldMapping_empty = {
  jobField: undefined,
  amocrmField: undefined
};

var JobFieldMapping = {
  empty: JobFieldMapping_empty,
  decode: decode$4
};

var decode$5 = JsonDecode.object(function (field) {
      return {
              leadField: field.required("leadField", decode$1),
              planadoField: field.required("planadoField", decode$3)
            };
    });

var LeadFieldMapping_empty = {
  leadField: undefined,
  planadoField: undefined
};

var LeadFieldMapping = {
  empty: LeadFieldMapping_empty,
  decode: decode$5
};

var emptyErrors = {
  templateUuid: undefined,
  statusIdOnSuccess: undefined,
  statusIdOnFailure: undefined
};

function empty(statusId) {
  return {
          uuid: Uuid.createV4(),
          configured: false,
          statusId: statusId,
          templateUuid: undefined,
          statusIdOnSuccess: undefined,
          statusIdOnFailure: undefined,
          jobFields: [],
          leadFields: [],
          errors: emptyErrors
        };
}

var decode$6 = JsonDecode.object(function (field) {
      return {
              uuid: field.required("uuid", Uuid.decode),
              configured: field.required("configured", JsonDecode.bool),
              statusId: field.required("statusId", Types.Id.decodeOpaque),
              templateUuid: Caml_option.some(field.required("templateUuid", Uuid.decode)),
              statusIdOnSuccess: Caml_option.some(field.required("statusIdOnSuccess", Types.Id.decodeOpaque)),
              statusIdOnFailure: Caml_option.some(field.required("statusIdOnFailure", Types.Id.decodeOpaque)),
              jobFields: field.required("jobFields", JsonDecode.array(decode$4)),
              leadFields: field.required("leadFields", JsonDecode.array(decode$5)),
              errors: emptyErrors
            };
    });

var ConfiguredStatus = {
  emptyErrors: emptyErrors,
  empty: empty,
  decode: decode$6
};

function make$1(id, statuses) {
  return {
          id: id,
          statuses: statuses
        };
}

function configuredStatus(statuses, id) {
  return Js_array.find((function (param) {
                return Types.Id.equal(param.statusId, id);
              }), statuses);
}

function empty$1(id) {
  return {
          id: id,
          statuses: []
        };
}

var decode$7 = JsonDecode.object(function (field) {
      return {
              id: field.required("pipelineId", Types.Id.decodeOpaque),
              statuses: field.required("statuses", JsonDecode.array(decode$6))
            };
    });

var empty$2 = {
  pipelines: []
};

function configuredPipeline(param, id) {
  return Js_array.find((function (param) {
                return Types.Id.equal(param.id, id);
              }), param.pipelines);
}

var decode$8 = JsonDecode.object(function (field) {
      return {
              pipelines: field.required("pipelines", JsonDecode.array(decode$7))
            };
    });

var Settings = {
  empty: empty$2,
  configuredStatus: configuredStatus,
  configuredPipeline: configuredPipeline,
  decode: decode$8
};

var decode$9 = JsonDecode.object(function (field) {
      return {
              id: field.required("id", Types.Id.decodeOpaque),
              name: field.required("name", JsonDecode.string)
            };
    });

var decode$10 = JsonDecode.object(function (field) {
      return {
              id: field.required("id", Types.Id.decodeOpaque),
              name: field.required("name", JsonDecode.string),
              statuses: field.required("statuses", JsonDecode.array(decode$9))
            };
    });

var decode$11 = JsonDecode.object(function (field) {
      return {
              uuid: field.required("uuid", Types.Id.decodeOpaque),
              name: field.required("name", JsonDecode.string),
              dataType: field.required("dataType", JsonDecode.map(JsonDecode.string, fromString$3))
            };
    });

var decode$12 = JsonDecode.object(function (field) {
      return {
              uuid: field.required("uuid", Types.Id.decodeOpaque),
              name: field.required("name", JsonDecode.string),
              dataType: field.required("dataType", JsonDecode.map(JsonDecode.string, fromString$3))
            };
    });

var decode$13 = JsonDecode.object(function (field) {
      return {
              uuid: field.required("uuid", Uuid.decode),
              name: field.required("name", JsonDecode.string),
              fields: field.required("fields", JsonDecode.array(decode$11)),
              reportFields: field.required("reportFields", JsonDecode.array(decode$12))
            };
    });

var Template = {
  decode: decode$13
};

var decode$14 = JsonDecode.object(function (field) {
      return {
              lead: field.required("lead", JsonDecode.array(decode)),
              contacts: field.required("contacts", JsonDecode.array(decode))
            };
    });

var decode$15 = JsonDecode.object(function (field) {
      return {
              id: field.required("id", Types.Id.decodeOpaque),
              domain: field.required("domain", JsonDecode.string),
              pipelines: field.required("pipelines", JsonDecode.array(decode$10)),
              customFields: field.required("customFields", decode$14)
            };
    });

var Account = {
  decode: decode$15
};

var decode$16 = JsonDecode.object(function (field) {
      return {
              domain: field.required("domain", JsonDecode.string),
              email: field.required("email", JsonDecode.option(JsonDecode.string))
            };
    });

var Credentials = {
  decode: decode$16
};

function make$2(account, settings) {
  return {
          account: account,
          settings: settings
        };
}

var Configuration = {
  make: make$2
};

var Field = {
  DataType: {
    fromStringLifter: fromStringLifter,
    toString: toString
  }
};

var CustomField = {};

var Predefined_Lead = {
  fromStringLifter: fromStringLifter$1,
  toString: toString$1,
  dataType: dataType,
  toId: toId,
  fromId: fromId,
  all: all
};

var Predefined_Contact = {
  fromStringLifter: fromStringLifter$2,
  toString: toString$2,
  toId: toId$1,
  all: all$1
};

var Predefined = {
  Lead: Predefined_Lead,
  Contact: Predefined_Contact
};

var LeadField = {
  getId: getId,
  makeFromCustomField: makeFromCustomField,
  makeFromPredefinedLead: makeFromPredefinedLead,
  decode: decode$1
};

var JobField_DataType = {
  fromStringLifter: fromStringLifter$3
};

var JobField_Predefined = {
  all: all$2,
  toString: toString$3,
  toId: toId$2,
  toDataType: toDataType
};

var JobField = {
  DataType: JobField_DataType,
  Predefined: JobField_Predefined,
  getId: getId$3
};

var ConfiguredPipeline = {
  make: make$1,
  empty: empty$1
};

var Status = {};

var Pipeline = {};

var TemplateField = {};

var ReportField = {};

var CustomFields = {};

export {
  Field ,
  CustomField ,
  Predefined ,
  LeadField ,
  ContactField ,
  AmocrmField ,
  JobField ,
  JobFieldMapping ,
  LeadFieldMapping ,
  ConfiguredStatus ,
  ConfiguredPipeline ,
  Settings ,
  Status ,
  Pipeline ,
  TemplateField ,
  ReportField ,
  Template ,
  CustomFields ,
  Account ,
  Credentials ,
  Configuration ,
}
/* decode Not a pure module */
