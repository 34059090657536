

import * as El from "../../../../libraries/El.mjs";
import * as Fun from "../../../../libraries/Fun.mjs";
import * as Hint from "../../../common/Hint/Hint.mjs";
import * as RCore from "../../../../libraries/RCore.mjs";
import * as Utils from "../../../../utils/Utils.mjs";
import * as React from "react";
import * as BillingModuleCss from "/home/runner/work/planado/planado/client/rescript/components/admin/billing/Billing.module.css";

var paymentByInvoice$p = Utils.Translations.tr("js.billing.show.payment_by_invoice");

var days$p = Utils.Translations.tx("js.billing.wizard.payment_method.days");

var invoiceTerms$p = Utils.Translations.txh("js.billing.wizard.payment_method.invoice_terms");

var recipient$p = Utils.Translations.tr("js.billing.wizard.payment_method.recipient");

var organizationName$p = Utils.Translations.tr("js.billing.wizard.payment_method.organization_name");

var orgNamePlaceholder$p = Utils.Translations.t("js.billing.wizard.payment_method.org_name_placeholder");

var enterOrganizationName$p = Utils.Translations.tr("js.billing.wizard.payment_method.enter_organization_name");

var setShortName$p = Utils.Translations.t("js.billing.wizard.payment_method.set_short_name");

var styles = BillingModuleCss;

function Billing_Wizard_PaymentInvoice(Props) {
  var param = Props.wire;
  var order = Props.order;
  var onOrderUpdate = Props.onOrderUpdate;
  var haveErrors = Props.haveErrors;
  var orgNameRef = Props.orgNameRef;
  var ctx = param.ctx;
  var updateOrgName = function ($$event) {
    var organizationName = $$event.target.value;
    onOrderUpdate(function (o) {
          return {
                  planTypeId: o.planTypeId,
                  paidSeats: o.paidSeats,
                  freeSeats: o.freeSeats,
                  monthsCycle: o.monthsCycle,
                  organizationName: Fun.optString(organizationName),
                  billingAddress: o.billingAddress,
                  paymentMethod: o.paymentMethod,
                  currency: o.currency,
                  technicalDiscount: o.technicalDiscount,
                  saleDiscount: o.saleDiscount,
                  priceAlgo: o.priceAlgo,
                  extraFeatureSet: o.extraFeatureSet
                };
        });
  };
  return React.createElement("div", undefined, React.createElement("form", {
                  method: "post"
                }, React.createElement("div", {
                      className: El.Cn.concat("row", styles.title)
                    }, React.createElement("div", {
                          className: "col-md-6"
                        }, React.createElement("h4", undefined, paymentByInvoice$p(ctx)))), React.createElement("div", {
                      className: El.Cn.concat("row", styles.methods)
                    }, React.createElement("div", {
                          className: "col-md-12"
                        }, React.createElement("div", undefined, React.createElement("div", undefined, invoiceTerms$p({
                                      days: days$p({
                                            count: 5
                                          }, ctx)
                                    }, ctx), React.createElement("div", {
                                      className: "row header"
                                    }, React.createElement("div", {
                                          className: "col-md-12"
                                        }, React.createElement("strong", undefined, recipient$p(ctx)), React.createElement(Hint.make, {
                                              content: setShortName$p(ctx)
                                            }))), React.createElement("div", {
                                      className: "form-group row string optional"
                                    }, React.createElement("label", {
                                          className: "string optional control-label col-sm-4 control-label"
                                        }, organizationName$p(ctx)), React.createElement("div", {
                                          className: El.Cn.fromRecord({
                                                "col-sm-8": true,
                                                "has-error": haveErrors
                                              })
                                        }, React.createElement("input", {
                                              ref: orgNameRef,
                                              className: "form-control",
                                              placeholder: orgNamePlaceholder$p(ctx),
                                              type: "text",
                                              value: RCore.$$Option.getOr(order.organizationName, ""),
                                              onChange: updateOrgName
                                            }), haveErrors ? React.createElement("div", {
                                                className: "help-block"
                                              }, enterOrganizationName$p(ctx)) : null))))))));
}

var Types;

var make = Billing_Wizard_PaymentInvoice;

export {
  Types ,
  make ,
}
/* paymentByInvoice' Not a pure module */
