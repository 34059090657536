

import * as RCore from "../../../libraries/RCore.mjs";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Billing_Types from "./Billing_Types.mjs";

function selectMaxPrice(planType) {
  return RCore.$$Option.map((function (__x) {
                  return RCore.$$Array.reduce(__x, undefined, (function (max, cur) {
                                if (max !== undefined && cur.amount <= max.amount) {
                                  return max;
                                } else {
                                  return cur;
                                }
                              }));
                })(planType.variants.flatMap(function (param) {
                      var priceScheme = param.priceScheme;
                      if (priceScheme === undefined) {
                        return [];
                      }
                      if (typeof priceScheme !== "object") {
                        return [];
                      }
                      switch (priceScheme.TAG) {
                        case "Flat" :
                            return [priceScheme._0];
                        case "Quoted" :
                        case "QuotedWithThreshold" :
                            break;
                        
                      }
                      return priceScheme._0.map(function (param) {
                                    return param.price;
                                  }).slice(0, 1);
                    })), (function (m) {
                return {
                        TAG: "Flat",
                        _0: m
                      };
              }));
}

function profit(baseScheme, planScheme) {
  if (baseScheme === undefined) {
    return ;
  }
  if (typeof baseScheme !== "object") {
    return ;
  }
  if (baseScheme.TAG !== "Flat") {
    return ;
  }
  if (typeof planScheme !== "object") {
    return ;
  }
  if (planScheme.TAG !== "Flat") {
    return ;
  }
  var planPrice = planScheme._0;
  var amount = baseScheme._0.amount;
  if (planPrice.amount > amount) {
    return {
            TAG: "Flat",
            _0: {
              amount: amount - planPrice.amount | 0,
              currency: planPrice.currency
            }
          };
  }
  
}

function filterPrice(acc, param) {
  var left = acc[1];
  if (left === 0) {
    return acc;
  }
  var quota = param.quota;
  var price = param.price;
  if (quota !== undefined && quota < left) {
    return [
            price,
            left - quota | 0
          ];
  } else {
    return [
            price,
            0
          ];
  }
}

function countSeats(order) {
  return order.paidSeats;
}

function featuresSeatPrice(features, freeFeatures, priceList) {
  var missingFeatures = features.flags.filter(function (feature) {
        return !freeFeatures.flags.includes(feature);
      });
  return RCore.$$Array.reduce(missingFeatures, Billing_Types.Money.zero(priceList.currency), (function (acc, feature) {
                var featurePrice = priceList.prices.features.find(function (param) {
                      return param.feature === feature;
                    });
                if (featurePrice !== undefined) {
                  return Billing_Types.Money.add(acc, featurePrice.price);
                } else {
                  return acc;
                }
              }));
}

function seatPriceForOrder(seatPrice, order, seats, freeFeatures, priceList) {
  var featuresSeatPrice$1 = featuresSeatPrice(order.extraFeatureSet, freeFeatures, priceList);
  var seatWithFeaturesPrice = Billing_Types.Money.add(seatPrice, featuresSeatPrice$1);
  var sd = order.saleDiscount;
  var saleDiscountPercent = sd !== undefined ? Caml_option.some(Billing_Types.SaleDiscount.forSeats(Caml_option.valFromOption(sd), seats)) : undefined;
  return Billing_Types.Money.applyDiscounts(seatWithFeaturesPrice, RCore.$$Array.keepSome([
                  order.technicalDiscount,
                  saleDiscountPercent
                ]));
}

function currentOrderPrice(order, planType, priceList) {
  var cycleVariants = RCore.$$Array.keep(planType.variants, (function (v) {
          return v.monthsCycle === order.monthsCycle;
        }));
  var variant = cycleVariants[0];
  if (variant === undefined) {
    return ;
  }
  var match = variant.priceScheme;
  if (match === undefined) {
    return ;
  }
  if (typeof match !== "object") {
    return ;
  }
  switch (match.TAG) {
    case "Flat" :
        return seatPriceForOrder(match._0, order, order.paidSeats, variant.features, priceList);
    case "Quoted" :
    case "QuotedWithThreshold" :
        break;
    
  }
  var seats = order.paidSeats;
  return RCore.$$Array.reduce(match._0, [
                undefined,
                seats
              ], filterPrice)[0];
}

function calcPrice(acc, param) {
  var left = acc[1];
  if (left === 0) {
    return acc;
  }
  var quota = param.quota;
  var amount = param.price.amount;
  var total = acc[0];
  if (quota !== undefined && quota < left) {
    return [
            total + Math.imul(amount, quota) | 0,
            left - quota | 0
          ];
  }
  return [
          total + Math.imul(amount, left) | 0,
          0
        ];
}

function monthlyAmountWithThresholds(prices, seats) {
  if (prices.length <= 0) {
    return ;
  }
  var first = RCore.$$Option.getExn(prices[0], undefined);
  return {
          amount: RCore.$$Array.reduce(prices, [
                  0,
                  seats
                ], calcPrice)[0],
          currency: first.price.currency
        };
}

function monthlyAmount(seats, scheme) {
  if (typeof scheme !== "object") {
    return ;
  }
  switch (scheme.TAG) {
    case "Flat" :
        var match = scheme._0;
        return {
                amount: Math.imul(match.amount, seats),
                currency: match.currency
              };
    case "Quoted" :
        return monthlyAmountWithThresholds(scheme._0, seats);
    case "QuotedWithThreshold" :
        var match$1 = scheme._1;
        var amt = monthlyAmountWithThresholds(scheme._0, seats);
        if (amt !== undefined) {
          if (amt.amount >= match$1.threshold.amount) {
            return amt;
          } else {
            return {
                    amount: amt.amount + match$1.charge.amount | 0,
                    currency: amt.currency
                  };
          }
        } else {
          return ;
        }
    
  }
}

function billAmount(order) {
  return function (scheme) {
    return RCore.$$Option.map(monthlyAmount(order.paidSeats, scheme), (function (param) {
                  return {
                          amount: Math.imul(param.amount, Billing_Types.Order.Cycle.toInt(order.monthsCycle)),
                          currency: param.currency
                        };
                }));
  };
}

function monthlyAmountNew(currentOrder, seats, variant, priceList) {
  var match = variant.priceScheme;
  if (match === undefined) {
    return ;
  }
  if (typeof match !== "object") {
    return ;
  }
  if (match.TAG !== "Flat") {
    return ;
  }
  var price = seatPriceForOrder(match._0, currentOrder, seats, variant.features, priceList);
  return Billing_Types.Money.mult(price, seats);
}

function billAmountNew(currentOrder, seats, variant, priceList) {
  var ma = monthlyAmountNew(currentOrder, seats, variant, priceList);
  if (ma === undefined) {
    return ;
  }
  var months = Billing_Types.Order.Cycle.toInt(currentOrder.monthsCycle);
  return Billing_Types.Money.mult(ma, months);
}

var Types;

export {
  Types ,
  selectMaxPrice ,
  profit ,
  countSeats ,
  currentOrderPrice ,
  monthlyAmount ,
  billAmount ,
  monthlyAmountNew ,
  billAmountNew ,
}
/* Billing_Types Not a pure module */
