

import * as El from "../../../libraries/El.mjs";
import * as RCore from "../../../libraries/RCore.mjs";
import * as React from "react";
import * as Locale from "../../../libraries/Locale.mjs";
import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as DomUtils from "../../../DomUtils.mjs";
import * as Optional from "../Optional.mjs";
import * as DateInput from "../../inputs/DateInput.mjs";
import * as AppContext from "../../../context/AppContext.mjs";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as DateRangeFilter_CalendarPopup from "./DateRangeFilter_CalendarPopup.mjs";
import * as DateRangeFilterModuleCss from "/home/runner/work/planado/planado/client/rescript/components/common/DateRangeFilter/DateRangeFilter.module.css";

var styles = DateRangeFilterModuleCss;

var CalendarButton = {};

function openUp(containerRef, offset) {
  var container = containerRef.current;
  if (container == null) {
    return false;
  }
  var position = DomUtils.BoundingClientRect.get(container);
  return ((position.bottom | 0) + (offset | 0) | 0) >= DomUtils.windowInnerHeight();
}

function DateRangeFilter(Props) {
  var filter = Props.onDatesChange;
  var value = Props.value;
  var buttons = Props.buttons;
  var disabled = Props.disabled;
  var match = React.useState(function () {
        
      });
  var finish = value[1];
  var start = value[0];
  var setFocusedInput = match[1];
  var onDatesChange = function (start, finish) {
    if (!disabled) {
      return filter(start, finish);
    }
    
  };
  var wire = AppContext.useWire();
  var containerRef = React.useRef(null);
  var format = Locale.T.fmtM("Date", wire);
  var focusHandler = function (input) {
    setFocusedInput(function (param) {
          return input;
        });
  };
  var changeStartDate = function (startDate) {
    onDatesChange(startDate, finish);
  };
  var changeFinishDate = function (finishDate) {
    var realFinishDate = Locale.T.isRangeValid(start, finishDate) ? finishDate : undefined;
    onDatesChange(start, realFinishDate);
  };
  var renderButtons = function () {
    return React.createElement(Optional.make, {
                show: Caml_obj.notequal(buttons, []),
                children: React.createElement("div", {
                      className: "j-calendar__buttons"
                    }, buttons.map(function (param) {
                          var match = param[2]();
                          var snd = match[1];
                          var fst = match[0];
                          var classes = start !== undefined && finish !== undefined && Locale.T.equalDay(Caml_option.valFromOption(start), fst) && Locale.T.equalDay(Caml_option.valFromOption(finish), snd) ? "j-calendar__button j-calendar__button--active" : "j-calendar__button";
                          return React.createElement("button", {
                                      key: param[0],
                                      className: classes,
                                      onClick: (function (param) {
                                          onDatesChange(Caml_option.some(fst), Caml_option.some(snd));
                                        })
                                    }, param[1]);
                        }))
              });
  };
  var calendarCN = openUp(containerRef, 350) ? styles.calendarRangeUp : styles.calendarDown;
  return React.createElement("div", {
              ref: containerRef
            }, React.createElement("div", {
                  className: "j-scheduled__inputs"
                }, React.createElement(DateInput.make, {
                      value: RCore.$$Option.map(start, format),
                      onFocus: (function (param) {
                          setFocusedInput(function (param) {
                                return "Start";
                              });
                        }),
                      onDateChange: changeStartDate,
                      disabled: disabled
                    }), React.createElement("div", {
                      className: "j-scheduled__inputs-separator"
                    }, El.ndash), React.createElement(DateInput.make, {
                      value: RCore.$$Option.map(finish, format),
                      onFocus: (function (param) {
                          setFocusedInput(function (param) {
                                return "End";
                              });
                        }),
                      onDateChange: changeFinishDate,
                      disabled: disabled
                    })), React.createElement("div", {
                  className: "j_scheduled_calendar"
                }, React.createElement("div", {
                      className: calendarCN
                    }, React.createElement(DateRangeFilter_CalendarPopup.make, {
                          focusedInput: match[0],
                          value: value,
                          buttons: renderButtons(),
                          onFocusChange: focusHandler,
                          onDatesChange: onDatesChange,
                          onOutsideClick: (function (evt) {
                              var onBlur = function () {
                                setFocusedInput(function (param) {
                                      
                                    });
                              };
                              var node = containerRef.current;
                              if (node == null) {
                                return ;
                              }
                              var target = evt.target;
                              if (DomUtils.contains(node, target)) {
                                return ;
                              } else {
                                return onBlur();
                              }
                            })
                        }))));
}

var make = DateRangeFilter;

export {
  CalendarButton ,
  make ,
}
/* styles Not a pure module */
