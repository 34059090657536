

import * as RCore from "../../libraries/RCore.mjs";
import * as Utils from "../../utils/Utils.mjs";
import * as React from "react";
import * as Backend from "../../libraries/backend/Backend.mjs";
import * as UI_Page from "../../ui/UI_Page.mjs";
import * as Optional from "../../components/common/Optional.mjs";
import * as UI_Button from "../../ui/UI_Button.mjs";
import * as AppContext from "../../context/AppContext.mjs";
import * as UI_PageTabs from "../../ui/UI_PageTabs.mjs";
import * as UI_PageHeader from "../../ui/UI_PageHeader.mjs";
import * as Pages_Client_Portal_API from "./Pages_Client_Portal_API.mjs";
import * as Pages_Client_Portal_Types from "./Pages_Client_Portal_Types.mjs";
import * as Pages_Client_Portal_Users from "./Pages_Client_Portal_Users.mjs";
import * as Pages_Client_Portal_Layout from "./Pages_Client_Portal_Layout.mjs";
import * as Pages_Client_Portal_Settings from "./Pages_Client_Portal_Settings.mjs";
import * as Pages_Client_PortalModuleCss from "/home/runner/work/planado/planado/client/rescript/pages/client_portal/Pages_Client_Portal.module.css";

var description$p = Utils.Translations.tr("js.client_portal.description");

var create$p = Utils.Translations.tr("js.client_portal.create");

var portal$p = Utils.Translations.tr("js.client_portal.title");

var crumbsSettings$p = Utils.Translations.tr("js.admin.settings.title.settings");

var layout$p = Utils.Translations.tr("js.client_portal.tabs.layout");

var settings$p = Utils.Translations.tr("js.client_portal.tabs.settings");

var users$p = Utils.Translations.tr("js.client_portal.tabs.users");

var styles = Pages_Client_PortalModuleCss;

function Pages_Client_Portal$PortalCreation(Props) {
  var setState = Props.setState;
  var wire = Props.wire;
  var match = React.useState(function () {
        return false;
      });
  var setDisabled = match[1];
  var onClick = function ($$event) {
    $$event.preventDefault();
    setDisabled(function (param) {
          return true;
        });
    Backend.$$finally(Backend.decode(Pages_Client_Portal_API.Index.Create.post(wire), Pages_Client_Portal_Types.State.Portal.decode), (function (result) {
            if (result.TAG === "Ok") {
              var r = result._0;
              setState(function (current) {
                    return {
                            portal: r,
                            templates: current.templates,
                            customFields: current.customFields,
                            reportFields: current.reportFields
                          };
                  });
            } else {
              console.log(result._0);
            }
            setDisabled(function (param) {
                  return false;
                });
          }));
  };
  return React.createElement("div", {
              className: styles.description
            }, description$p(wire.ctx), React.createElement(UI_Button.make, {
                  flavor: "outline",
                  className: styles.createButton,
                  disabled: match[0],
                  onClick: onClick,
                  children: create$p(wire.ctx)
                }));
}

function Pages_Client_Portal(Props) {
  var wire = AppContext.useWire();
  var ctx = wire.ctx;
  var match = React.useState(function () {
        return Pages_Client_Portal_Types.State.empty;
      });
  var setState = match[1];
  var state = match[0];
  var match$1 = React.useState(function () {
        return false;
      });
  var setLoaded = match$1[1];
  React.useEffect((function () {
          Backend.$$finally(Backend.decode(Pages_Client_Portal_API.Index.Show.get(wire), Pages_Client_Portal_Types.State.decode), (function (result) {
                  if (result.TAG === "Ok") {
                    var r = result._0;
                    setLoaded(function (param) {
                          return true;
                        });
                    return setState(function (param) {
                                return r;
                              });
                  }
                  console.log(result._0);
                }));
        }), []);
  var portal = state.portal;
  var tmp;
  if (portal !== undefined) {
    var setPortal = function (fun) {
      setState(function (current) {
            return {
                    portal: RCore.$$Option.map(current.portal, fun),
                    templates: current.templates,
                    customFields: current.customFields,
                    reportFields: current.reportFields
                  };
          });
    };
    tmp = React.createElement(UI_PageTabs.make, {
          className: styles.wrapper,
          children: null
        }, React.createElement(UI_PageTabs.Tab.make, {
              route: {
                NAME: "ClientPortal",
                VAL: "Layout"
              },
              renderLabel: (function () {
                  return layout$p(ctx);
                }),
              children: React.createElement(Pages_Client_Portal_Layout.make, {
                    portal: portal,
                    setPortal: setPortal,
                    templates: state.templates,
                    customFields: state.customFields,
                    reportFields: state.reportFields
                  })
            }), React.createElement(UI_PageTabs.Tab.make, {
              route: {
                NAME: "ClientPortal",
                VAL: "Users"
              },
              renderLabel: (function () {
                  return users$p(ctx);
                }),
              children: React.createElement(Pages_Client_Portal_Users.make, {
                    portal: portal,
                    setPortal: setPortal
                  })
            }), React.createElement(UI_PageTabs.Tab.make, {
              route: {
                NAME: "ClientPortal",
                VAL: "Settings"
              },
              renderLabel: (function () {
                  return settings$p(ctx);
                }),
              children: React.createElement(Pages_Client_Portal_Settings.make, {
                    portal: portal,
                    setPortal: setPortal
                  })
            }));
  } else {
    tmp = React.createElement(Pages_Client_Portal$PortalCreation, {
          setState: setState,
          wire: wire
        });
  }
  return React.createElement(UI_Page.make, {
              children: null
            }, React.createElement(UI_PageHeader.make, {
                  wrapperClassName: styles.headerWrapper,
                  children: null
                }, crumbsSettings$p(ctx), React.createElement("span", undefined, portal$p(ctx))), React.createElement(Optional.make, {
                  show: match$1[0],
                  children: tmp
                }));
}

var make = Pages_Client_Portal;

export {
  make ,
}
/* description' Not a pure module */
