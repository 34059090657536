

import * as El from "../../../../libraries/El.mjs";
import * as Icon from "../../../../Icon.mjs";
import * as Radio from "../../../inputs/Radio.mjs";
import * as Utils from "../../../../utils/Utils.mjs";
import * as React from "react";
import * as Billing_Types from "../Billing_Types.mjs";
import * as Billing_Components_Blocks from "../components/Billing_Components_Blocks.mjs";
import * as Billing_Wizard_Components from "./Billing_Wizard_Components.mjs";
import * as Billing_Wizard_PaymentInvoice from "./Billing_Wizard_PaymentInvoice.mjs";
import * as BillingModuleCss from "/home/runner/work/planado/planado/client/rescript/components/admin/billing/Billing.module.css";

var continue$p = Utils.Translations.tr("common.continue");

var choosePlanBtn$p = Utils.Translations.tr("js.billing.wizard.choose_plan_btn");

var paymentMethod$p = Utils.Translations.tr("js.billing.payment_method.payment_method");

var payCrypto$p = Utils.Translations.t("js.billing.payment_methods.crypto");

var payInvoice$p = Utils.Translations.t("js.billing.payment_methods.invoice");

var payPaddle$p = Utils.Translations.t("js.billing.payment_methods.paddle");

var styles = BillingModuleCss;

function Billing_Wizard_PaymentMethods(Props) {
  var wire = Props.wire;
  var order = Props.order;
  var availablePaymentMethods = Props.availablePaymentMethods;
  var goBack = Props.goBack;
  var onContinue = Props.onContinue;
  var updatePaymentMethod = Props.updatePaymentMethod;
  var onOrderUpdate = Props.onOrderUpdate;
  var locked = Props.locked;
  var planType = Props.planType;
  var planVariant = Props.planVariant;
  var actualPriceList = Props.actualPriceList;
  var ctx = wire.ctx;
  var match = React.useState(function () {
        return false;
      });
  var setHaveErrors = match[1];
  var orgNameRef = React.useRef(null);
  var paymentLabel = function (method) {
    switch (method) {
      case "Invoice" :
          return payInvoice$p(ctx);
      case "Card" :
          return payPaddle$p(ctx);
      case "Crypto" :
          return payCrypto$p(ctx);
      
    }
  };
  var match$1 = order.paymentMethod;
  var disabledSubmit;
  switch (match$1) {
    case "Invoice" :
        disabledSubmit = locked;
        break;
    case "Card" :
    case "Crypto" :
        disabledSubmit = false;
        break;
    
  }
  var invoiceSubmit = function () {
    var match = order.organizationName;
    var match$1 = orgNameRef.current;
    if (match !== undefined) {
      return onContinue();
    } else if (!(match$1 == null)) {
      return setHaveErrors(function (param) {
                  return true;
                });
    } else {
      return ;
    }
  };
  var onSubmit = function () {
    var match = order.paymentMethod;
    switch (match) {
      case "Invoice" :
          return invoiceSubmit();
      case "Card" :
      case "Crypto" :
          return onContinue();
      
    }
  };
  var match$2 = order.paymentMethod;
  var tmp;
  switch (match$2) {
    case "Invoice" :
        tmp = React.createElement(Billing_Wizard_PaymentInvoice.make, {
              wire: wire,
              order: order,
              onOrderUpdate: onOrderUpdate,
              haveErrors: match[0],
              orgNameRef: orgNameRef
            });
        break;
    case "Card" :
    case "Crypto" :
        tmp = null;
        break;
    
  }
  return React.createElement("div", undefined, React.createElement(Billing_Wizard_Components.PageHeader.make, {
                  ctx: ctx
                }), React.createElement("div", {
                  className: El.Cn.concat(El.Cn.concat(styles.billing, "form-row row"), styles.choosePaymentMethod)
                }, React.createElement("div", {
                      className: "col-md-12 form-container"
                    }, React.createElement(Billing_Components_Blocks.TrialEnded.make, {
                          ctx: ctx
                        }), React.createElement(Billing_Components_Blocks.TrialEnds.make, {
                          wire: wire
                        }), React.createElement("div", {
                          className: styles.wizard
                        }, React.createElement(Billing_Wizard_Components.Steps.make, {
                              current: "ChoosePaymentMethod",
                              ctx: ctx
                            }), React.createElement("div", {
                              className: El.Cn.concat(El.Cn.concat(styles.wizardContent, styles.panelBody), styles.billingContainer)
                            }, React.createElement("div", undefined, React.createElement(Billing_Components_Blocks.Order.make, {
                                      order: order,
                                      monthsCycle: order.monthsCycle,
                                      wire: wire,
                                      planType: planType,
                                      planVariant: planVariant,
                                      actualPriceList: actualPriceList
                                    }), React.createElement("div", {
                                      className: styles.billingBlock
                                    }, availablePaymentMethods.length > 1 ? React.createElement(React.Fragment, undefined, React.createElement("div", {
                                                className: El.Cn.concat("row", styles.title)
                                              }, React.createElement("div", {
                                                    className: "col-md-6"
                                                  }, React.createElement("h4", undefined, paymentMethod$p(ctx)))), React.createElement("div", {
                                                className: styles.pickPaymentMethod
                                              }, availablePaymentMethods.map(function (method) {
                                                    return React.createElement(Radio.make, {
                                                                onChange: (function (param) {
                                                                    updatePaymentMethod(method);
                                                                  }),
                                                                checked: order.paymentMethod === method,
                                                                labelText: paymentLabel(method),
                                                                wrapperClass: styles.radioPaymentMethod,
                                                                key: Billing_Types.PaymentMethod.toString(method)
                                                              });
                                                  }))) : null, tmp))), React.createElement("div", {
                              className: styles.wizardButtons
                            }, React.createElement("button", {
                                  className: "btn",
                                  onClick: goBack
                                }, React.createElement("i", {
                                      className: Icon.style(undefined, undefined, "chevron-left")
                                    }), El.space, choosePlanBtn$p(ctx)), React.createElement("button", {
                                  className: "btn btn-primary",
                                  disabled: disabledSubmit,
                                  onClick: (function (param) {
                                      onSubmit();
                                    })
                                }, continue$p(ctx)))))));
}

var Types;

var make = Billing_Wizard_PaymentMethods;

export {
  Types ,
  make ,
}
/* continue' Not a pure module */
