

import * as El from "../../../../libraries/El.mjs";
import * as Link from "../../../common/Link/Link.mjs";
import * as RCore from "../../../../libraries/RCore.mjs";
import * as Style from "../../../../libraries/Style.mjs";
import * as Types from "../../../../types/Types.mjs";
import * as Utils from "../../../../utils/Utils.mjs";
import * as React from "react";
import * as Backend from "../../../../libraries/backend/Backend.mjs";
import * as Billing_API from "../Billing_API.mjs";
import * as Billing_Types from "../Billing_Types.mjs";
import * as Billing_Wizard_Footer from "./Billing_Wizard_Footer.mjs";
import * as Billing_Wizard_Components from "./Billing_Wizard_Components.mjs";
import * as Billing_Components_Periods from "../components/Billing_Components_Periods.mjs";
import * as Billing_Components_PlanPrice from "../components/Billing_Components_PlanPrice.mjs";
import * as Billing_Components_PlanHeader from "../components/Billing_Components_PlanHeader.mjs";
import * as Billing_Components_PlanFeatures from "../components/Billing_Components_PlanFeatures.mjs";
import * as BillingModuleCss from "/home/runner/work/planado/planado/client/rescript/components/admin/billing/Billing.module.css";

var numberOfLicenses$p = Utils.Translations.tr("js.billing.wizard.number_of_licenses");

var choosePlan$p = Utils.Translations.tr("js.billing.wizard.choose_plan");

var licenses$p = Utils.Translations.txr("js.billing.licenses_no_count");

var licensesFreeWarning$p = Utils.Translations.txh("js.billing.wizard.licenses_warning_free");

var paymentPeriod$p = Utils.Translations.tr("js.billing.wizard.payment_period");

function periodDescription$p(period, ctx) {
  return Utils.Translations.th("js.billing.wizard.period_description.months_" + period)(ctx);
}

var comment$p = Utils.Translations.tr("js.billing.wizard.comment");

var enterprise$p = Utils.Translations.th("js.billing.wizard.enterprise_description");

var requestSent$p = Utils.Translations.tr("js.billing.wizard.request_sent");

var users$p = Utils.Translations.txr("js.billing.wizard.users_dative");

var userSettings$p = Utils.Translations.th("js.billing.wizard.settings_users");

var styles = BillingModuleCss;

function lessThanPeriods(periods, period) {
  return RCore.$$Array.keep(periods, (function (p) {
                return Billing_Types.Order.Cycle.toInt(p) < Billing_Types.Order.Cycle.toInt(period);
              })).length;
}

function Billing_Wizard_ChoosePlan(Props) {
  var order = Props.order;
  var windowed = Props.windowed;
  var currentPlanType = Props.currentPlanType;
  var currentPlanVariant = Props.currentPlanVariant;
  var onOrderUpdate = Props.onOrderUpdate;
  var availablePlanTypes = Props.availablePlanTypes;
  var priceRequest = Props.priceRequest;
  var onPriceRequested = Props.onPriceRequested;
  var currentSeats = Props.currentSeats;
  var actualPriceList = Props.actualPriceList;
  var wire = Props.wire;
  var ctx = wire.ctx;
  var match = React.useMemo((function () {
          return [
                  RCore.$$Array.getBy(availablePlanTypes, (function (t) {
                          return t.kind === "special";
                        })),
                  RCore.$$Array.getBy(availablePlanTypes, (function (t) {
                          return t.kind === "free";
                        }))
                ];
        }), [availablePlanTypes]);
  var free = match[1];
  var periods = React.useMemo((function () {
          return RCore.$$Array.keep([
                      "one",
                      "two",
                      "three",
                      "four",
                      "six",
                      "year"
                    ], (function (cycle) {
                        return availablePlanTypes.some(function (param) {
                                    return param.variants.some(function (param) {
                                                return param.monthsCycle === cycle;
                                              });
                                  });
                      }));
        }), []);
  var isChosen = function (planType) {
    return RCore.$$Option.getOr(RCore.$$Option.map(planType, (function (e) {
                      return Types.Id.equal(e.id, order.planTypeId);
                    })), false);
  };
  var enterpriseChosen = isChosen(match[0]);
  var freeChosen = isChosen(free);
  var plansRef = React.useRef(null);
  var match$1 = React.useState(function () {
        
      });
  var setSelectorWidth = match$1[1];
  var selectorWidth = match$1[0];
  React.useLayoutEffect((function () {
          var el = plansRef.current;
          if (!(el == null)) {
            setTimeout((function () {
                    setSelectorWidth(function (param) {
                          return Utils.size(el)[0];
                        });
                  }), 10);
            return ;
          }
          
        }), []);
  var match$2 = React.useState(function () {
        
      });
  var setHoveredPlan = match$2[1];
  var hoveredPlan = match$2[0];
  var unhoverPlan = function (t) {
    if (hoveredPlan !== undefined && Types.Id.equal(hoveredPlan.id, t.id)) {
      return setHoveredPlan(function (param) {
                  
                });
    }
    
  };
  var match$3 = React.useState(function () {
        return false;
      });
  var setPriceRequested = match$3[1];
  var match$4 = React.useState(function () {
        
      });
  var setComment = match$4[1];
  var comment = match$4[0];
  React.useEffect((function () {
          var priceRequested = function (result) {
            if (result.TAG === "Ok") {
              return setPriceRequested(function (param) {
                          return true;
                        });
            }
            
          };
          return priceRequest.subscribe(function () {
                      Backend.$$finally(Billing_API.RequestPrice.POST.create(wire, RCore.$$Option.getOr(comment, "")), priceRequested);
                      onPriceRequested();
                    });
        }), []);
  var tmp;
  if (selectorWidth !== undefined) {
    var planWidth = selectorWidth / availablePlanTypes.length;
    var idx = RCore.$$Array.findIndexOpt(availablePlanTypes, (function (t) {
            return Types.Id.equal(t.id, currentPlanType.id);
          }));
    tmp = idx !== undefined ? React.createElement("div", {
            className: styles.currentPlan,
            style: {
              left: (idx * planWidth - 4.0 | 0).toString() + "px",
              width: (planWidth | 0).toString() + "px"
            }
          }) : null;
  } else {
    tmp = null;
  }
  var tmp$1;
  if (enterpriseChosen) {
    tmp$1 = match$3[0] ? React.createElement("div", {
            className: styles.pricingRequested
          }, requestSent$p(ctx)) : React.createElement("div", undefined, React.createElement("h4", undefined, comment$p(ctx)), React.createElement("div", {
                className: styles.requestComment
              }, React.createElement("textarea", {
                    className: "form-control",
                    value: RCore.$$Option.getOr(comment, ""),
                    onChange: (function (e) {
                        var value = e.target.value;
                        setComment(value);
                      })
                  }), React.createElement("div", {
                    className: styles.hint
                  }, enterprise$p(ctx))));
  } else {
    var tmp$2;
    if (freeChosen) {
      var seatsLimit = RCore.$$Option.flatMap(RCore.$$Option.flatMap(free, (function (p) {
                  return p.variants[0];
                })), (function (v) {
              return v.features.seatsLimit;
            }));
      var exit = 0;
      if (seatsLimit !== undefined && seatsLimit < (order.paidSeats + order.freeSeats | 0)) {
        tmp$2 = React.createElement("p", {
              className: styles.licensesWarning
            }, licensesFreeWarning$p({
                  limit: seatsLimit,
                  licenses: licenses$p({
                        count: currentSeats
                      }, ctx),
                  users: users$p({
                        count: currentSeats
                      }, ctx)
                }, ctx), React.createElement(Link.make, {
                  wire: wire,
                  route: {
                    NAME: "User",
                    VAL: "Index"
                  },
                  children: userSettings$p(ctx)
                }));
      } else {
        exit = 1;
      }
      if (exit === 1) {
        tmp$2 = React.createElement(Billing_Wizard_Footer.make, {
              ctx: ctx,
              order: order,
              planType: currentPlanType,
              planVariant: currentPlanVariant,
              actualPriceList: actualPriceList
            });
      }
      
    } else {
      var tmp$3;
      if (selectorWidth !== undefined) {
        var colWidth = selectorWidth / periods.length;
        var idx$1 = lessThanPeriods(periods, order.monthsCycle);
        tmp$3 = React.createElement("div", {
              className: styles.currentPeriod,
              style: {
                left: (idx$1 * colWidth - 4.0 | 0).toString() + "px",
                width: (colWidth | 0).toString() + "px"
              }
            });
      } else {
        tmp$3 = null;
      }
      tmp$2 = React.createElement(React.Fragment, undefined, React.createElement("div", {
                className: styles.seats
              }, React.createElement("h4", undefined, numberOfLicenses$p(ctx)), React.createElement(Billing_Wizard_Components.Licenses.make, {
                    wire: wire,
                    order: order,
                    currentSeats: currentSeats,
                    onOrderUpdate: onOrderUpdate
                  })), React.createElement("div", {
                className: styles.periods
              }, React.createElement("h4", undefined, paymentPeriod$p(ctx)), React.createElement("div", {
                    className: styles.periodOptions
                  }, tmp$3, React.createElement(Billing_Components_Periods.make, {
                        ctx: ctx,
                        onClick: (function (monthsCycle) {
                            return function (param) {
                              onOrderUpdate({
                                    planTypeId: order.planTypeId,
                                    paidSeats: order.paidSeats,
                                    freeSeats: order.freeSeats,
                                    monthsCycle: monthsCycle,
                                    organizationName: order.organizationName,
                                    billingAddress: order.billingAddress,
                                    paymentMethod: order.paymentMethod,
                                    currency: order.currency,
                                    technicalDiscount: order.technicalDiscount,
                                    saleDiscount: order.saleDiscount,
                                    priceAlgo: order.priceAlgo,
                                    extraFeatureSet: order.extraFeatureSet
                                  });
                            };
                          }),
                        periods: periods,
                        order: order,
                        currentPlanType: currentPlanType
                      })), React.createElement("div", {
                    className: El.Cn.concat(styles.periodDescription, styles.paid)
                  }, periodDescription$p(Billing_Types.Order.Cycle.toInt(order.monthsCycle).toString(), ctx)), React.createElement(Billing_Wizard_Footer.make, {
                    ctx: ctx,
                    order: order,
                    planType: currentPlanType,
                    planVariant: currentPlanVariant,
                    actualPriceList: actualPriceList
                  })));
    }
    tmp$1 = React.createElement(React.Fragment, undefined, tmp$2);
  }
  return React.createElement("form", {
              method: "post"
            }, React.createElement("div", {
                  className: Style.fromTuples([styles.plans], [[
                          styles.windowed,
                          windowed
                        ]])
                }, React.createElement("h4", {
                      className: styles.first
                    }, choosePlan$p(ctx)), React.createElement("div", {
                      className: styles.planOptions
                    }, tmp, React.createElement("table", {
                          ref: plansRef
                        }, React.createElement("thead", undefined, React.createElement("tr", undefined, availablePlanTypes.map(function (t, index) {
                                      var f = function () {
                                        onOrderUpdate({
                                              planTypeId: t.id,
                                              paidSeats: order.paidSeats,
                                              freeSeats: order.freeSeats,
                                              monthsCycle: order.monthsCycle,
                                              organizationName: order.organizationName,
                                              billingAddress: order.billingAddress,
                                              paymentMethod: order.paymentMethod,
                                              currency: order.currency,
                                              technicalDiscount: order.technicalDiscount,
                                              saleDiscount: order.saleDiscount,
                                              priceAlgo: order.priceAlgo,
                                              extraFeatureSet: order.extraFeatureSet
                                            });
                                      };
                                      return React.createElement(Billing_Components_PlanHeader.make, {
                                                  ctx: ctx,
                                                  onMouseEnter: (function (param) {
                                                      setHoveredPlan(function (param) {
                                                            return t;
                                                          });
                                                    }),
                                                  onMouseLeave: (function (param) {
                                                      unhoverPlan(t);
                                                    }),
                                                  onClick: (function (param) {
                                                      f();
                                                    }),
                                                  hoveredPlan: hoveredPlan,
                                                  order: order,
                                                  current: false,
                                                  planType: t,
                                                  key: index.toString()
                                                });
                                    }))), React.createElement("tbody", undefined, React.createElement("tr", {
                                  className: styles.price
                                }, availablePlanTypes.map(function (t, index) {
                                      var f = function () {
                                        onOrderUpdate({
                                              planTypeId: t.id,
                                              paidSeats: order.paidSeats,
                                              freeSeats: order.freeSeats,
                                              monthsCycle: order.monthsCycle,
                                              organizationName: order.organizationName,
                                              billingAddress: order.billingAddress,
                                              paymentMethod: order.paymentMethod,
                                              currency: order.currency,
                                              technicalDiscount: order.technicalDiscount,
                                              saleDiscount: order.saleDiscount,
                                              priceAlgo: order.priceAlgo,
                                              extraFeatureSet: order.extraFeatureSet
                                            });
                                      };
                                      return React.createElement(Billing_Components_PlanPrice.make, {
                                                  ctx: ctx,
                                                  onMouseEnter: (function (param) {
                                                      setHoveredPlan(function (param) {
                                                            return t;
                                                          });
                                                    }),
                                                  onMouseLeave: (function (param) {
                                                      unhoverPlan(t);
                                                    }),
                                                  onClick: (function (param) {
                                                      f();
                                                    }),
                                                  hoveredPlan: hoveredPlan,
                                                  order: order,
                                                  planType: t,
                                                  actualPriceList: actualPriceList,
                                                  key: index.toString()
                                                });
                                    })), React.createElement("tr", undefined, availablePlanTypes.map(function (t, index) {
                                      var f = function () {
                                        onOrderUpdate({
                                              planTypeId: t.id,
                                              paidSeats: order.paidSeats,
                                              freeSeats: order.freeSeats,
                                              monthsCycle: order.monthsCycle,
                                              organizationName: order.organizationName,
                                              billingAddress: order.billingAddress,
                                              paymentMethod: order.paymentMethod,
                                              currency: order.currency,
                                              technicalDiscount: order.technicalDiscount,
                                              saleDiscount: order.saleDiscount,
                                              priceAlgo: order.priceAlgo,
                                              extraFeatureSet: order.extraFeatureSet
                                            });
                                      };
                                      return React.createElement(Billing_Components_PlanFeatures.make, {
                                                  ctx: ctx,
                                                  onMouseEnter: (function (param) {
                                                      setHoveredPlan(function (param) {
                                                            return t;
                                                          });
                                                    }),
                                                  onMouseLeave: (function (param) {
                                                      unhoverPlan(t);
                                                    }),
                                                  onClick: (function (param) {
                                                      f();
                                                    }),
                                                  hoveredPlan: hoveredPlan,
                                                  order: order,
                                                  planType: t,
                                                  key: index.toString()
                                                });
                                    })))))), tmp$1);
}

var make = Billing_Wizard_ChoosePlan;

export {
  make ,
}
/* numberOfLicenses' Not a pure module */
