

import * as El from "../../../../libraries/El.mjs";
import * as RCore from "../../../../libraries/RCore.mjs";
import * as Utils from "../../../../utils/Utils.mjs";
import * as React from "react";
import * as Billing_Types from "../Billing_Types.mjs";
import * as Billing_Components_PlanPrice from "./Billing_Components_PlanPrice.mjs";
import * as BillingModuleCss from "/home/runner/work/planado/planado/client/rescript/components/admin/billing/Billing.module.css";

var over50Seats$p = Utils.Translations.tr("js.billing.wizard.over_50_licenses");

var sms$p = Utils.Translations.txr("js.billing.wizard.features.sms");

var smsNoLimit$p = Utils.Translations.tr("js.billing.wizard.features.sms_no_limit");

var teams$p = Utils.Translations.tr("js.billing.wizard.features.teams");

var shiftsAndTeams$p = Utils.Translations.tr("js.billing.wizard.features.shifts_and_teams");

var noTeams$p = Utils.Translations.tr("js.billing.wizard.features.no_teams");

var noSignature$p = Utils.Translations.tr("js.billing.wizard.features.no_signature");

var apiAndWebhooks$p = Utils.Translations.tr("js.billing.wizard.features.api_and_webhooks");

var zapier$p = Utils.Translations.tr("js.billing.wizard.features.zapier");

var seats$p = Utils.Translations.txr("js.billing.licenses_no_count");

var noSms$p = Utils.Translations.tr("js.billing.wizard.features.no_sms");

var signature$p = Utils.Translations.tr("js.billing.wizard.features.signature");

var recurringJobs$p = Utils.Translations.tr("js.billing.wizard.features.recurring_jobs");

var crm$p = Utils.Translations.tr("js.billing.wizard.features.crm");

var territories$p = Utils.Translations.tr("js.billing.wizard.features.territories");

var noTerritories$p = Utils.Translations.tr("js.billing.wizard.features.no_territories");

var limitedStorage$p = Utils.Translations.tr("js.billing.wizard.features.limited_storage");

var unlimitedStorage$p = Utils.Translations.tr("js.billing.wizard.features.unlimited_storage");

var mileageTracking$p = Utils.Translations.tr("js.billing.wizard.features.mileage_tracking");

var linkedJobs$p = Utils.Translations.tr("js.billing.wizard.features.linked_jobs");

var printLayouts$p = Utils.Translations.tr("js.billing.wizard.features.print_layouts");

var bulkOperations$p = Utils.Translations.tr("js.billing.wizard.features.bulk_operations");

var availableLater$p = Utils.Translations.tr("js.billing.wizard.available_later");

var createJobsInApp$p = Utils.Translations.tr("js.billing.wizard.features.create_jobs_in_app");

var customFields$p = Utils.Translations.tr("js.billing.wizard.features.custom_fields");

var smsGateway$p = Utils.Translations.tr("js.billing.wizard.features.sms_gateway");

var styles = BillingModuleCss;

function feature(v) {
  return React.createElement("div", {
              className: styles.feature
            }, v);
}

function Billing_Components_PlanFeatures(Props) {
  var ctx = Props.ctx;
  var onMouseEnter = Props.onMouseEnter;
  var onMouseLeave = Props.onMouseLeave;
  var onClick = Props.onClick;
  var hoveredPlan = Props.hoveredPlan;
  var order = Props.order;
  var planType = Props.planType;
  var planVariant = RCore.$$Option.getExn(planType.variants[0], undefined);
  var className = Billing_Components_PlanPrice.Utils.planClass(hoveredPlan, order, planType);
  var features = planVariant.features;
  var tmp;
  if (planType.name.code === "Enterprise") {
    tmp = feature(over50Seats$p(ctx));
  } else {
    var limit = features.seatsLimit;
    var tmp$1;
    if (Billing_Types.Features.hasFlag("sms", features)) {
      var limit$1 = features.smsPerSeat;
      tmp$1 = feature(limit$1 !== undefined ? sms$p({
                  limit: limit$1
                }, ctx) : smsNoLimit$p(ctx));
    } else {
      tmp$1 = Billing_Types.Features.hasFlag("smsGateway", features) ? feature(smsGateway$p(ctx)) : feature(noSms$p(ctx));
    }
    tmp = React.createElement("div", undefined, limit !== undefined ? React.createElement("div", {
                className: styles.feature
              }, limit.toString(), El.space, seats$p({
                    count: limit
                  }, ctx)) : null, tmp$1, Billing_Types.Features.hasFlag("teams", features) ? (
            Billing_Types.Features.hasFlag("shifts", features) ? feature(shiftsAndTeams$p(ctx)) : feature(teams$p(ctx))
          ) : feature(noTeams$p(ctx)), Billing_Types.Features.hasFlag("territories", features) ? feature(territories$p(ctx)) : feature(noTerritories$p(ctx)), Billing_Types.Features.hasFlag("linkedJobs", features) ? feature(linkedJobs$p(ctx)) : null, Billing_Types.Features.hasFlag("signature", features) ? feature(signature$p(ctx)) : feature(noSignature$p(ctx)), Billing_Types.Features.hasFlag("api", features) ? feature(apiAndWebhooks$p(ctx)) : null, Billing_Types.Features.hasFlag("recurringJobs", features) ? feature(recurringJobs$p(ctx)) : null, Billing_Types.Features.hasFlag("tracking", features) ? feature(mileageTracking$p(ctx)) : null, Billing_Types.Features.hasAnyFlag([
              "bitrix24",
              "amocrm"
            ], features) ? feature(crm$p(ctx)) : null, Billing_Types.Features.hasFlag("zapier", features) ? feature(zapier$p(ctx)) : null, Billing_Types.Features.hasFlag("printLayouts", features) ? feature(printLayouts$p(ctx)) : null, Billing_Types.Features.hasFlag("bulkOperations", features) ? feature(bulkOperations$p(ctx)) : null, Billing_Types.Features.hasFlag("createJobsInApp", features) ? feature(createJobsInApp$p(ctx)) : null, Billing_Types.Features.hasFlag("customFields", features) ? feature(customFields$p(ctx)) : null, Billing_Types.Features.hasFlag("smsGateway", features) ? feature(smsGateway$p(ctx)) : null, planType.name.code === "Free" ? feature(limitedStorage$p(ctx)) : feature(unlimitedStorage$p(ctx)), planType.name.code === "Free" ? feature(availableLater$p(ctx)) : null);
  }
  return React.createElement("td", {
              className: className,
              onClick: onClick,
              onMouseEnter: onMouseEnter,
              onMouseLeave: onMouseLeave
            }, tmp);
}

var make = Billing_Components_PlanFeatures;

export {
  make ,
}
/* over50Seats' Not a pure module */
