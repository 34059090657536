

import * as El from "../../../../libraries/El.mjs";
import * as RCore from "../../../../libraries/RCore.mjs";
import * as Utils from "../../../../utils/Utils.mjs";
import * as React from "react";
import * as Billing_Types from "../Billing_Types.mjs";
import * as BillingModuleCss from "/home/runner/work/planado/planado/client/rescript/components/admin/billing/Billing.module.css";

var months$p = Utils.Translations.txr("js.billing.wizard.months");

var discount$p = Utils.Translations.txr("js.billing.wizard.discount");

var styles = BillingModuleCss;

function Billing_Components_Periods(Props) {
  var ctx = Props.ctx;
  var onClick = Props.onClick;
  var periods = Props.periods;
  var order = Props.order;
  var currentPlanType = Props.currentPlanType;
  var p = function (period, index) {
    var variant = currentPlanType.variants.find(function (param) {
          return param.monthsCycle === period;
        });
    var planExists = RCore.$$Option.isSome(variant);
    var className = period === order.monthsCycle ? styles.current : "";
    var className$1 = planExists ? className : El.Cn.concat(className, styles.unavailablePeriod);
    var tmp;
    if (variant !== undefined) {
      var discountPercent = variant.discountPercent;
      if (discountPercent > 0) {
        var className$2 = currentPlanType.kind === "custom" ? El.Cn.concat(styles.discount, styles.invisibleDiscount) : styles.discount;
        tmp = React.createElement("div", {
              className: className$2
            }, discount$p({
                  discount: discountPercent
                }, ctx));
      } else {
        tmp = null;
      }
    } else {
      tmp = null;
    }
    return React.createElement("td", {
                key: index.toString(),
                className: className$1,
                onClick: onClick(period)
              }, React.createElement("div", undefined, months$p({
                        count: Billing_Types.Order.Cycle.toInt(period)
                      }, ctx)), tmp);
  };
  return React.createElement("table", {
              className: styles.periods
            }, React.createElement("tbody", undefined, React.createElement("tr", undefined, periods.map(p))));
}

var Types;

var make = Billing_Components_Periods;

export {
  Types ,
  make ,
}
/* months' Not a pure module */
