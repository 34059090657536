

import * as Utils from "../../utils/Utils.mjs";
import * as React from "react";
import * as Locale from "../../libraries/Locale.mjs";
import * as DateRangeFilter from "../common/DateRangeFilter/DateRangeFilter.mjs";
import * as ReportsModuleCss from "/home/runner/work/planado/planado/client/rescript/components/reports/Reports.module.css";

var statusPlaceholder$p = Utils.Translations.t("js.reports.jobs.status_filter.placeholder");

var statusFilterTitle$p = Utils.Translations.tr("js.reports.jobs.status_filter.title");

var finishedAtFilter$p = Utils.Translations.tr("js.reports.jobs.date_filter.title");

var thisWeek$p = Utils.Translations.tr("js.reports.jobs.date_filter.this_week");

var prevWeek$p = Utils.Translations.tr("js.reports.jobs.date_filter.prev_week");

var fourWeek$p = Utils.Translations.tr("js.reports.jobs.date_filter.four_week");

var q1$p = Utils.Translations.tr("js.reports.jobs.date_filter.quarters.first");

var q2$p = Utils.Translations.tr("js.reports.jobs.date_filter.quarters.second");

var q3$p = Utils.Translations.tr("js.reports.jobs.date_filter.quarters.third");

var q4$p = Utils.Translations.tr("js.reports.jobs.date_filter.quarters.fourth");

var Translations = {
  statusPlaceholder$p: statusPlaceholder$p,
  statusFilterTitle$p: statusFilterTitle$p,
  finishedAtFilter$p: finishedAtFilter$p,
  thisWeek$p: thisWeek$p,
  prevWeek$p: prevWeek$p,
  fourWeek$p: fourWeek$p,
  q1$p: q1$p,
  q2$p: q2$p,
  q3$p: q3$p,
  q4$p: q4$p
};

var styles = ReportsModuleCss;

function Reports_FinishedAtFilter(Props) {
  var wire = Props.wire;
  var period = Props.period;
  var setPeriodDate = Props.setPeriodDate;
  var ctx = wire.ctx;
  var last4weeks = function () {
    return [
            Locale.T.startOf("day", Locale.T.next(Locale.T.now(wire), {
                      NAME: "weeks",
                      VAL: -4
                    })),
            Locale.T.endOf("day", Locale.T.now(wire))
          ];
  };
  var match = Locale.T.quarter(Locale.T.now(wire));
  var quarterButtons;
  switch (match) {
    case 1 :
        quarterButtons = [
          [
            "Q4",
            q4$p(ctx),
            (function () {
                return Locale.T.makeRange(Locale.T.now(wire), {
                            NAME: "quarters",
                            VAL: -1
                          });
              })
          ],
          [
            "Q1",
            q1$p(ctx),
            (function () {
                return Locale.T.makeRange(Locale.T.now(wire), {
                            NAME: "quarters",
                            VAL: 0
                          });
              })
          ]
        ];
        break;
    case 2 :
        quarterButtons = [
          [
            "Q1",
            q1$p(ctx),
            (function () {
                return Locale.T.makeRange(Locale.T.now(wire), {
                            NAME: "quarters",
                            VAL: -1
                          });
              })
          ],
          [
            "Q2",
            q2$p(ctx),
            (function () {
                return Locale.T.makeRange(Locale.T.now(wire), {
                            NAME: "quarters",
                            VAL: 0
                          });
              })
          ]
        ];
        break;
    case 3 :
        quarterButtons = [
          [
            "Q2",
            q2$p(ctx),
            (function () {
                return Locale.T.makeRange(Locale.T.now(wire), {
                            NAME: "quarters",
                            VAL: -1
                          });
              })
          ],
          [
            "Q3",
            q3$p(ctx),
            (function () {
                return Locale.T.makeRange(Locale.T.now(wire), {
                            NAME: "quarters",
                            VAL: 0
                          });
              })
          ]
        ];
        break;
    case 4 :
        quarterButtons = [
          [
            "Q3",
            q3$p(ctx),
            (function () {
                return Locale.T.makeRange(Locale.T.now(wire), {
                            NAME: "quarters",
                            VAL: -1
                          });
              })
          ],
          [
            "Q4",
            q4$p(ctx),
            (function () {
                return Locale.T.makeRange(Locale.T.now(wire), {
                            NAME: "quarters",
                            VAL: 0
                          });
              })
          ]
        ];
        break;
    default:
      quarterButtons = [];
  }
  var buttons = [
        [
          "this-week",
          thisWeek$p(ctx),
          (function () {
              return Locale.T.makeRange(Locale.T.now(wire), {
                          NAME: "weeks",
                          VAL: 0
                        });
            })
        ],
        [
          "last-week",
          prevWeek$p(ctx),
          (function () {
              return Locale.T.makeRange(Locale.T.now(wire), {
                          NAME: "weeks",
                          VAL: -1
                        });
            })
        ],
        [
          "4-weeks",
          fourWeek$p(ctx),
          last4weeks
        ]
      ].concat(quarterButtons).concat([[
          "this-year",
          String(Locale.T.year(Locale.T.now(wire))),
          (function () {
              return Locale.T.makeRange(Locale.T.now(wire), {
                          NAME: "years",
                          VAL: 0
                        });
            })
        ]]);
  return React.createElement("div", {
              className: styles.dateFilterContainer
            }, React.createElement("span", undefined, finishedAtFilter$p(ctx)), React.createElement("div", {
                  className: styles.dateFilter
                }, React.createElement(DateRangeFilter.make, {
                      onDatesChange: setPeriodDate,
                      value: period,
                      buttons: buttons,
                      disabled: false
                    })));
}

var make = Reports_FinishedAtFilter;

export {
  Translations ,
  styles ,
  make ,
}
/* statusPlaceholder' Not a pure module */
