

import * as Url from "../../bindings/url/Url.mjs";
import * as RCore from "../../libraries/RCore.mjs";
import * as Types from "../../types/Types.mjs";
import * as Utils from "../../utils/Utils.mjs";
import * as React from "react";
import * as Locale from "../../libraries/Locale.mjs";
import * as Js_dict from "rescript/lib/es6/js_dict.js";
import * as Js_null from "rescript/lib/es6/js_null.js";
import * as DomUtils from "../../DomUtils.mjs";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as MultiSelect from "../inputs/MultiSelect.mjs";
import * as Reports_FinishedAtFilter from "./Reports_FinishedAtFilter.mjs";
import * as ReportsModuleCss from "/home/runner/work/planado/planado/client/rescript/components/reports/Reports.module.css";

var statusPlaceholder$p = Utils.Translations.t("js.reports.jobs.status_filter.placeholder");

var statusFilterTitle$p = Utils.Translations.tr("js.reports.jobs.status_filter.title");

var posted$p = Utils.Translations.t("js.reports.jobs.status_filter.posted");

var scheduled$p = Utils.Translations.t("js.reports.jobs.status_filter.scheduled");

var published$p = Utils.Translations.t("js.reports.jobs.status_filter.published");

var enRoute$p = Utils.Translations.t("js.reports.jobs.status_filter.en_route");

var started$p = Utils.Translations.t("js.reports.jobs.status_filter.started");

var suspended$p = Utils.Translations.t("js.reports.jobs.status_filter.suspended");

var canceled$p = Utils.Translations.t("js.reports.jobs.status_filter.canceled");

var finished$p = Utils.Translations.t("js.reports.jobs.status_filter.finished");

var Translations = {
  statusPlaceholder$p: statusPlaceholder$p,
  statusFilterTitle$p: statusFilterTitle$p,
  posted$p: posted$p,
  scheduled$p: scheduled$p,
  published$p: published$p,
  enRoute$p: enRoute$p,
  started$p: started$p,
  suspended$p: suspended$p,
  canceled$p: canceled$p,
  finished$p: finished$p
};

function toString(status) {
  switch (status) {
    case "Posted" :
        return "posted";
    case "Scheduled" :
        return "scheduled";
    case "Published" :
        return "published";
    case "EnRoute" :
        return "en_route";
    case "Started" :
        return "started";
    case "Suspended" :
        return "suspended";
    case "Canceled" :
        return "canceled";
    case "Finished" :
        return "finished";
    
  }
}

function fromString(status) {
  switch (status) {
    case "canceled" :
        return "Canceled";
    case "enRoute" :
    case "en_route" :
        return "EnRoute";
    case "finished" :
        return "Finished";
    case "posted" :
        return "Posted";
    case "published" :
        return "Published";
    case "scheduled" :
        return "Scheduled";
    case "started" :
        return "Started";
    case "suspended" :
        return "Suspended";
    default:
      return ;
  }
}

var Status = {
  toString: toString,
  fromString: fromString
};

function statusOptions(ctx) {
  return [
          {
            value: "posted",
            label: posted$p(ctx)
          },
          {
            value: "scheduled",
            label: scheduled$p(ctx)
          },
          {
            value: "published",
            label: published$p(ctx)
          },
          {
            value: "en_route",
            label: enRoute$p(ctx)
          },
          {
            value: "started",
            label: started$p(ctx)
          },
          {
            value: "suspended",
            label: suspended$p(ctx)
          },
          {
            value: "canceled",
            label: canceled$p(ctx)
          },
          {
            value: "finished",
            label: finished$p(ctx)
          }
        ];
}

function $$default(wire) {
  return {
          statuses: [],
          finishedAt: [
            Locale.T.startOf("day", Locale.T.next(Locale.T.now(wire), {
                      NAME: "weeks",
                      VAL: -4
                    })),
            Locale.T.endOf("day", Locale.T.now(wire))
          ]
        };
}

function encode(filters) {
  var statuses = filters.statuses.map(toString).map(function (s) {
        return s;
      });
  var finishedAt = RCore.$$Option.map(filters.finishedAt, (function (param) {
          return Js_dict.fromArray([
                      [
                        "start",
                        Locale.T.isoDate(param[0])
                      ],
                      [
                        "end",
                        Locale.T.isoDate(param[1])
                      ]
                    ]);
        }));
  return {
          statusFilter: statuses,
          finishedAtFilter: Js_null.fromOption(finishedAt)
        };
}

var Filters = {
  Status: Status,
  statusOptions: statusOptions,
  $$default: $$default,
  encode: encode
};

var styles = ReportsModuleCss;

function Reports_Jobs(Props) {
  var wire = Props.wire;
  var iframeHeight = Props.iframeHeight;
  var ctx = wire.ctx;
  var match = React.useState(function () {
        return $$default(wire);
      });
  var setFilters = match[1];
  var filters = match[0];
  var match$1 = React.useState(function () {
        var match = filters.finishedAt;
        if (match !== undefined) {
          return [
                  Caml_option.some(match[0]),
                  Caml_option.some(match[1])
                ];
        } else {
          return [
                  undefined,
                  undefined
                ];
        }
      });
  var setPeriod = match$1[1];
  var iframeRef = React.useRef(null);
  var setStatusFilter = function (statuses) {
    setFilters(function (param) {
          return {
                  statuses: RCore.$$Array.keepSome(statuses.map(Types.Id.toString).map(fromString)),
                  finishedAt: filters.finishedAt
                };
        });
  };
  var setPeriodDate = function (fst, snd) {
    setPeriod(function (param) {
          return [
                  fst,
                  snd
                ];
        });
    if (fst === undefined) {
      return ;
    }
    if (snd === undefined) {
      return ;
    }
    var finish = Caml_option.valFromOption(snd);
    var start = Caml_option.valFromOption(fst);
    setFilters(function (param) {
          return {
                  statuses: filters.statuses,
                  finishedAt: [
                    start,
                    finish
                  ]
                };
        });
  };
  var postMessage = function (url) {
    var iframe = iframeRef.current;
    if (!(iframe == null)) {
      return DomUtils.$$Window.postMessage(DomUtils.$$Window.contentWindow(iframe), encode(filters), Url.toString(url));
    }
    
  };
  React.useEffect((function () {
          var match = iframeRef.current;
          var match$1 = ctx.reportsUrl;
          if (match == null) {
            return ;
          }
          if (match$1 === undefined) {
            return ;
          }
          var url = Caml_option.valFromOption(match$1);
          DomUtils.Listeners.onLoad(undefined, match, (function (param) {
                  setTimeout((function () {
                          postMessage(url);
                        }), 100);
                }));
          return (function () {
                    DomUtils.Listeners.removeOnLoad(undefined, match, (function (param) {
                            postMessage(url);
                          }));
                  });
        }), []);
  React.useEffect((function () {
          var url = ctx.reportsUrl;
          if (url !== undefined) {
            postMessage(Caml_option.valFromOption(url));
          }
          
        }), [filters]);
  var url = wire.ctx.reportsUrl;
  if (url !== undefined) {
    return React.createElement(React.Fragment, undefined, React.createElement("div", {
                    className: styles.filtersContainer
                  }, React.createElement(Reports_FinishedAtFilter.make, {
                        wire: wire,
                        period: match$1[0],
                        setPeriodDate: setPeriodDate
                      }), React.createElement("div", {
                        className: styles.statusFilterContainer
                      }, React.createElement("span", undefined, statusFilterTitle$p(ctx)), React.createElement("div", {
                            className: styles.statusFilter
                          }, React.createElement(MultiSelect.make, {
                                placeholder: statusPlaceholder$p(ctx),
                                id: "statuses-filter",
                                onChange: setStatusFilter,
                                value: filters.statuses.map(toString).map(Types.Id.fromString),
                                disabled: false,
                                options: statusOptions(ctx),
                                multi: true
                              })))), React.createElement("iframe", {
                    ref: iframeRef,
                    id: "reports-iframe",
                    style: {
                      border: "none",
                      minHeight: "100vh",
                      overflow: "hidden"
                    },
                    height: iframeHeight,
                    scrolling: "no",
                    src: Url.toString(Caml_option.valFromOption(url)),
                    width: "100%"
                  }));
  } else {
    return null;
  }
}

var make = Reports_Jobs;

export {
  Translations ,
  Filters ,
  styles ,
  make ,
}
/* statusPlaceholder' Not a pure module */
