

import * as Uuid from "../../bindings/uuid/Uuid.mjs";
import * as RCore from "../../libraries/RCore.mjs";
import * as JsonDecode from "../../libraries/JsonDecode.mjs";
import * as Types_Date from "../../types/Types_Date.mjs";
import * as Template_Types from "../../components/admin/template/Template_Types.mjs";
import * as JobEdit_JobType from "../../components/job/edit/lib/types/JobEdit_JobType.mjs";

function toString(jobField) {
  switch (jobField) {
    case "Address" :
        return "address";
    case "Assignee" :
        return "assignee";
    case "CreatedAt" :
        return "created_at";
    case "Description" :
        return "description";
    case "EnRouteAt" :
        return "en_route_at";
    case "FinishedAt" :
        return "finished_at";
    case "Resolution" :
        return "resolution";
    case "ScheduledAt" :
        return "scheduled_at";
    case "ScheduledDuration" :
        return "scheduled_duration";
    case "StartedAt" :
        return "started_at";
    case "Status" :
        return "status";
    case "Template" :
        return "template";
    case "WorkDuration" :
        return "work_duration";
    
  }
}

function fromString(value) {
  switch (value) {
    case "address" :
        return "Address";
    case "assignee" :
        return "Assignee";
    case "created_at" :
        return "CreatedAt";
    case "description" :
        return "Description";
    case "en_route_at" :
        return "EnRouteAt";
    case "finished_at" :
        return "FinishedAt";
    case "resolution" :
        return "Resolution";
    case "scheduled_at" :
        return "ScheduledAt";
    case "scheduled_duration" :
        return "ScheduledDuration";
    case "started_at" :
        return "StartedAt";
    case "status" :
        return "Status";
    case "template" :
        return "Template";
    case "work_duration" :
        return "WorkDuration";
    default:
      return ;
  }
}

var decode = JsonDecode.fromVariant(JsonDecode.string, fromString);

var allFields = [
  "ScheduledAt",
  "Status",
  "Template",
  "Address",
  "Assignee",
  "FinishedAt",
  "Resolution",
  "Description",
  "CreatedAt",
  "EnRouteAt",
  "ScheduledDuration",
  "StartedAt",
  "WorkDuration"
];

var alwaysOnFields = [
  "ScheduledAt",
  "Address",
  "Status",
  "Template",
  "Assignee"
];

var restFields = [
  "FinishedAt",
  "Resolution",
  "Description",
  "CreatedAt",
  "EnRouteAt",
  "ScheduledDuration",
  "StartedAt",
  "WorkDuration"
];

var JobField = {
  toString: toString,
  fromString: fromString,
  decode: decode,
  allFields: allFields,
  alwaysOnFields: alwaysOnFields,
  restFields: restFields
};

var decode$1 = JsonDecode.object(function (field) {
      return {
              jobFields: field.required("jobFields", JsonDecode.array(decode)),
              customFields: field.required("customFields", JsonDecode.array(Uuid.decode)),
              reportFields: field.required("reportFields", JsonDecode.array(Uuid.decode))
            };
    });

var decode$2 = JsonDecode.object(function (field) {
      return {
              templateUuids: field.required("templateUuids", JsonDecode.array(Uuid.decode)),
              columns: field.required("columns", decode$1)
            };
    });

var decode$3 = JsonDecode.object(function (field) {
      return {
              uuid: field.required("uuid", Uuid.decode),
              clientUuid: field.required("clientUuid", Uuid.decode),
              name: field.required("name", JsonDecode.string),
              phoneNumber: field.required("phoneNumber", JsonDecode.option(JsonDecode.string)),
              email: field.required("email", JsonDecode.option(JsonDecode.string)),
              lastSignInAt: field.required("lastSignInAt", JsonDecode.option(Types_Date.decode))
            };
    });

var User = {
  decode: decode$3
};

function toString$1(status) {
  if (status === "Enabled") {
    return "enabled";
  } else {
    return "disabled";
  }
}

function fromString$1(value) {
  switch (value) {
    case "disabled" :
        return "Disabled";
    case "enabled" :
        return "Enabled";
    default:
      return ;
  }
}

var Status = {
  toString: toString$1,
  fromString: fromString$1
};

var decode$4 = JsonDecode.object(function (field) {
      return {
              uuid: field.required("uuid", Uuid.decode),
              logo: field.required("logo", JsonDecode.option(JobEdit_JobType.Field.Attachment.decode)),
              users: field.required("users", JsonDecode.array(decode$3)),
              settings: field.required("settings", decode$2),
              status: field.required("status", JsonDecode.fromVariant(JsonDecode.string, fromString$1)),
              emailInvitationStencil: RCore.$$Option.getOr(field.required("emailInvitationStencil", JsonDecode.option(JsonDecode.string)), ""),
              smsInvitationStencil: RCore.$$Option.getOr(field.required("smsInvitationStencil", JsonDecode.option(JsonDecode.string)), "")
            };
    });

var decode$5 = JsonDecode.object(function (field) {
      return {
              uuid: field.required("uuid", Uuid.decode),
              name: field.required("name", JsonDecode.string)
            };
    });

var empty_templates = [];

var empty_customFields = [];

var empty_reportFields = [];

var empty = {
  portal: undefined,
  templates: empty_templates,
  customFields: empty_customFields,
  reportFields: empty_reportFields
};

var decode$6 = JsonDecode.object(function (field) {
      return {
              portal: field.required("portal", JsonDecode.option(decode$4)),
              templates: field.required("templates", JsonDecode.array(Template_Types.Template.decode)),
              customFields: field.required("customFields", JsonDecode.array(decode$5)),
              reportFields: field.required("reportFields", JsonDecode.array(decode$5))
            };
    });

var $$let = JobField;

var State_Portal = {
  Settings: {
    Columns: {
      JobField: {
        toString: $$let.toString,
        allFields: $$let.allFields,
        alwaysOnFields: $$let.alwaysOnFields,
        restFields: $$let.restFields
      }
    }
  },
  User: User,
  Status: Status,
  decode: decode$4
};

var State_CustomReportField = {};

var State = {
  Portal: State_Portal,
  CustomReportField: State_CustomReportField,
  empty: empty,
  decode: decode$6
};

export {
  State ,
}
/* decode Not a pure module */
